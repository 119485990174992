<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <!-- {{ $route.query }} -->
        <c-table
          :url="'CustomerWebService/get_customers'"
          :per-page="10"
          :fields="columns"
          :columns="[
            'firstname',
            'lastname',
            'register',
            'phone',
            'email',
            'loan_max_amount',
            'created_date',
            'contracted_date',
            'employerName',
            'employerDistrict',
            'emergency_number',
            'contracted_email',
            'contracted_number',
            'extra_info'
          ]"
          :filename="'Хэрэглэгч'"
          :filters="filters"
          orderDirection="DESC"
          orderBy="created_date"
          hover
          bordered
          fixed
          caption=""
        >
        </c-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Customers.list",
  data: function() {
    return {
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Овог",
          key: "lastname",
          sortable: true,
          sort_key: "lastname",
        },
        {
          label: "Нэр",
          key: "firstname",
          sortable: true,
          sort_key: "firstname",
        },
        {
          label: "Регистр",
          key: "register",
          sortable: true,
          sort_key: "register",
        },
        { label: "Утас", key: "phone", sortable: true, sort_key: "phone" },
        // { label: 'И-мэйл', key: 'email'},
        {
          label: "Зээлийн лимит",
          key: "maxAmount",
          sortable: true,
          sort_key: "loan_max_amount",
          class: "text-right",
        },
        {
          label: "НД",
          key: "isPaidNd",
          sortable: true,
          sort_key: "is_contract",
          class: "text-center",
        },
        {
          label: "Гэрээ",
          key: "isContract",
          sortable: true,
          sort_key: "is_contract",
          class: "text-center",
        },
        {
          label: "Гэрээ байгуулсан ажилтан",
          key: "contractedDtl",
          class: "text-center",
        },
        { label: "Идэвхтэй", key: "isActive", class: "text-center" },
        {
          label: "Зээлийн эрх",
          key: "isIncomeInfo",
          sortable: true,
          sort_key: "is_contract",
          class: "text-center",
        },
        {
          label: "Гэрээ байгуулсан огноо",
          key: "contractedDate",
          sortable: true,
          sort_key: "contracted_date",
        },
        {
          label: "Төлбөр хийх өдөр",
          key: "repaymentDay",
          sortable: true,
          sort_key: "repayment_day",
        },
        {
          label: "Үүсгэсэн огноо",
          key: "createdDate",
          sortable: true,
          sort_key: "created_date",
        },
        { label: "НД төлөгдсөн газар", key: "lastNd" },
      ],
      filters: [
        { label: "Овог нэр", key: "fullname", value: "" },
        { label: "Регистр", key: "register", value: "" },
        { label: "Утас", key: "phone", value: "" },
        {
          label: "Зээлийн эрх",
          key: "loan_max_amount",
          filter_type: "amount",
          value: { min: 0, max: 0 },
        },
        {
          label: "Гэрээ",
          key: "is_contract",
          filter_type: "boolean",
          value: null,
        },
        {
          label: "Гэрээ байгуулсан огноо",
          key: "contracted_date",
          filter_type: "date",
          value: {
            startDate: moment().subtract(1000, "days"),
            endDate: moment(),
          },
        },
        {
          label: "Төлбөр хийх өдөр",
          key: "repayment_day",
          filter_type: "number",
          value: { min: 0, max: 0 },
        },
      ],
    };
  },
  created: function() {
    var _buttons = [];
    if (this.checkPermission("admin.customer.xyp")) {
      _buttons.push({
        icon: "",
        label: "ХУР",
        variant: "outline-primary",
        route: "admin.customer.xyp",
        route_param: "personId",
      });
      _buttons.push({
        icon: "",
        label: "ДАН",
        variant: "outline-primary",
        route: "admin.customer.dan",
        route_param: "personId",
      });
    }
    if (this.checkPermission("admin.customer.detail")) {
      _buttons.push({
        icon: "fa fa-id-card",
        label: "",
        variant: "outline-primary",
        title: "Хэрэглэгчийн дэлгэрэнгүй",
        route: "admin.customer.detail",
        route_param: "id",
      });
    }
    if (_buttons.length > 0) {
      this.$data.columns.push({
        label: "Үйлдэл",
        key: "actions",
        buttons: _buttons,
        class: "text-center",
      });
    }
  },
};
</script>
